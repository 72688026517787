import React from "react";
import success from "../../images/success.svg";
import "./style.css";
const ApplicationSuccess = (props) => {
  console.log(props, "props");
  return (
    <div className="application-success-wrapper">
      <img src={success} alt="success" />

      <p>Your Application Has Been Submitted Successfully</p>
      <div className="button-wrapper">
        <a href="/Home" className="btn btn-primary">
          Go To Dashboard
        </a>
      </div>
    </div>
  );
};

export default ApplicationSuccess;
