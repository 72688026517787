import React from "react";
import queryString from "query-string";
import { Switch, Route, Redirect, withRouter } from "react-router";
import LandingBanner from "../LandingBanner";
import SigninWidget from "../SignInWidget";
import ResetPassword from "../PreLoginForms/ResetPasswordForm";
import PoweredByLeadSquared from "../PoweredByLeadSquared";
import { landingData, appLogo } from "./data";
import "./style.css";
import Header from "../Header/index.js";

const LandingLayout = (props) => {
  const signinProcessQueryParam = queryString.parse(props.location.search);
  const signinProcess = signinProcessQueryParam.Ref
    ? signinProcessQueryParam.Ref.toUpperCase()
    : "REGISTER";

  return (
    <div className="landing-wrapper">
      <div className="main-wrapper">
        <div className="website-intro-wrapper ">
          <div className="landing-mobile-header">
            <img src={appLogo.path} alt={appLogo.alt} />
          </div>
          <div className="landing-banner-wrapper">
            <LandingBanner data={landingData} />
          </div>
          <div className="form-wrapper">
            <div className="form-logo-container">
              <div className="logo-container">
                <img src={appLogo.path} alt={appLogo.alt} />
              </div>
              {props.location.pathname.includes("/ResetPassword") === true ? (
                <Switch>
                  <Route path="/ResetPassword" component={ResetPassword} />
                  <Route component={() => <Redirect to="/" />} />
                </Switch>
              ) : (
                <SigninWidget state={signinProcess} logo={appLogo} />
              )}
            </div>
          </div>
        </div>
      </div>
      <PoweredByLeadSquared />
    </div>
  );
};

export default withRouter(LandingLayout);

