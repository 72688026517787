import { connect } from "react-redux";
import { AuthSessionInfo } from "../actions/index";
import Registration from "../components/PreLoginForms/Registration";
import { withRouter } from "react-router-dom";

function mapStateToProps(state) {
  return {
    login: state.login,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateLoginInfo: function (loginInfo) {
      dispatch(AuthSessionInfo.updateLoginInfo(loginInfo));
    },
  };
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Registration)
);
