import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import AppLogo from "../../images/logo.png";
import UserProfileContainer from "../../containers/UserProfileContainer";
import LogoutContainer from "../../containers/LogoutContainer";
import "./style.css";

const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" fixed="top">
      <div className="container">
        <Navbar.Brand href="/">
          <img src={AppLogo} alt="LeadSquared University" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <div className="nav-item d-none d-lg-block">
              <a href="/">Dashboard</a>
            </div>
          </Nav>
          <Nav className="ml-auto">
            <div className="d-none nav-item d-lg-block">
              <UserProfileContainer />
            </div>
            <div className="nav-item d-block d-lg-none">
              <a href="/">Dashboard</a>
            </div>
            <div className="nav-item d-block d-lg-none">
              <LogoutContainer />
            </div>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default Header;
