var config = {
  AuthKey:
    "RjBQZnhpWlYvRGpoczV5Q2lOc0Fwb0prOGllMEM1V3Y3c25IUFNRQmJmclB2N3FRR28ydVpXL1pxYXlqZCtPSlpsTmpwc0VEWjdNdVBVbW4xa3dCSjdOdEpNZFZXTm9yV0xWS0xPRklMeGQ0bDZEcWRwUHlzbVl0bzZNbkJXcEREZEEwMjRoWXR4VS9DUEQreGlSNWZTbDh5MjRlRG11S1lTb2Z6TzAwRDBZSmxDUmFwOHNpOHR2OUptdHZBNmc3VXF1c01MeUtVWUV5VWdSYnZ5NXR3QT09",
  portalBaseURL: "https://portalapi-in21.leadsquared.com/api/Form",
  lsqFormBaseURL: "https://in21.leadsquared.com",
  lsqFormURL:
    "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js?v=65.0.2.2",
  lsqFormId: "a1215d2c-765f-11eb-a53a-0aa4c559f4ca",

  lsqFormProcessId: "65ecc5e8-7cd0-11eb-a53a-0aa4c559f4ca",

  lsqRegistrationFormId: "9e26e63a-767a-11eb-a53a-0aa4c559f4ca",
  lsqRegistrationFormBaseURL:
    "https://dhx9mmhpfsala.cloudfront.net/cdn/externalforms/r21/js/lsq.form.js?v=65.0.2.2",
};

export default config;
