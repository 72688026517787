/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { API_Login } from "../../api/signing-request";
import { API_GetLeadsById } from "../../api/lead-activity-api";

import AuthHandler from "../../utility/AuthHandler";
import { FORM_PLACEHOLDER } from "../../constants/placeholder";
import { ERROR_MSG } from "../../constants/error";
import Input from "../Input";
import Button from "../Button";

const LoginForm = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formMessage, setFormMessage] = useState("");

  const onhandleSubmit = () => {
    setIsLoading(true);
    API_Login({ EmailAddress: email, Password: password })
      .then(function (response) {
        API_GetLeadsById({
          leadId: response.data.LeadId,
        })
          .then(function (res) {
            const completeLeadDetails = res.data.message[0];
            console.log("data", completeLeadDetails);

            const authSessionInfo = {
              isAuthenticated: true,
              leadId: response.data.LeadId,
              authKey: response.data.AuthKey,
            };

            if (completeLeadDetails) {
              const leadDetails = JSON.stringify({
                email: completeLeadDetails.EmailAddress,
                firstName: completeLeadDetails.FirstName,
                lastName: completeLeadDetails.LastName,
              });
              sessionStorage.setItem("user-info", leadDetails);
            }

            AuthHandler.SetAuthSessionInfoCookie(authSessionInfo);
            props.updateLoginInfo(authSessionInfo);
            props.history.push("/Home");
            setIsLoading(false);
          })
          .catch(function (error) {
            console.log(error, "error");
          });
      })
      .catch(function (error) {
        const errorMessage =
          error.response.data.ExceptionMessage &&
          error.response.data.ExceptionMessage.includes("{")
            ? ERROR_MSG.ALREADY_LOGIN
            : error.response.data.ExceptionMessage;
        setIsLoading(false);
        setFormMessage(errorMessage);
      });
  };

  return (
    <>
      <div className="form-skeleton mt-5">
        {formMessage && <p className="form-message"> {formMessage}</p>}
        <form
          action=""
          method="post"
          className="form-wrapper-box"
          onSubmit={(e) => {
            e.preventDefault();
            onhandleSubmit();
          }}
        >
          <Input
            type="email"
            handleChange={(value) => {
              setEmail(value);
            }}
            handleBlur={() => {}}
            value={email}
            name="Email"
            error={false}
            label="Email"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.EMAIL}
          />

          <Input
            type="password"
            handleChange={(value) => {
              setPassword(value);
            }}
            handleBlur={() => {}}
            value={password}
            name="Password"
            error={false}
            label="Password"
            mandatory={true}
            placeholder={FORM_PLACEHOLDER.PASSWORD}
            sideLink={
              <div className="forgot-password">
                <a
                  href="javascript:void(0)"
                  onClick={() => props.changeWidgetState("FORGOTPASSWORD")}
                >
                  Forgot Password?
                </a>
              </div>
            }
          />
          <div className="form-group">
            <Button
              disabled={!email || !password}
              type="submit"
              className="btn-primary submit-button"
              text="Login"
              status={isLoading}
            />
          </div>
        </form>
        <div className="form-footer">
          Don't have an account?{" "}
          <a
            href="javascript:void(0)"
            onClick={() => props.changeWidgetState("REGISTER")}
          >
            Signup
          </a>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
