import React from "react";
import { Route, Switch, Redirect } from "react-router";
import Dashboard from "../components/Dashboard";
import ApplicationFormContainer from "../containers/ApplicationFormContainer";
import ApplicationFormPreviewContainer from "../containers/ApplicationFormPreviewContainer";
import ApplicationSuccessContainer from "../containers/ApplicationSuccessContainer";


const PostLoginRoute = () => {
  return (
    <>
      <Switch>
        <Route exact path={["/", "/Home"]} component={Dashboard} />
        <Route
          exact
          path="/Home/Form/:activityId?"
          component={ApplicationFormContainer}
        />
        <Route
          path="/Home/ApplicationForm/Preview/:activityId?"
          component={ApplicationFormPreviewContainer}
        />
        <Route
          path="/Home/ApplicationFormSuccess"
          component={ApplicationSuccessContainer}
        />
       
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </>
  );
};

export default PostLoginRoute;
