import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import LogoutContainer from "../../containers/LogoutContainer";
import { getSessionData } from "../../utility/common";

const UserProfile = () => {
  const [leadDetails, setLeadDetails] = useState();
  const placement = "bottom";

  useEffect(() => {
    const userInfo = getSessionData("user-info");
    console.log("userinfo", userInfo);
    setLeadDetails(userInfo);
  }, []);

  return (
    <div
      id="userProfileOptions"
      className="user-profile-options"
      style={{ display: "flex", alignItems: "center" }}
    >
      <OverlayTrigger
        rootClose
        trigger={["focus", "click"]}
        key={placement}
        placement={placement}
        overlay={
          <Popover
            id={`popover-positioned-${placement}`}
            className="d-none d-lg-block"
            style={{
              // marginRight: "60px",
              marginTop: "10px",
              borderRadius: "5px!important",
            }}
          >
            <div className="user-profile-content">
              <ul className="dropdown ">
                <li className="py-2">
                  <LogoutContainer />
                </li>
              </ul>
            </div>
          </Popover>
        }
      >
        <span
          className="d-flex align-items-center"
          style={{ cursor: "pointer" }}
        >
          <span className="user-profile-icon-wrapper px-2">
            <i
              id="userProfileOptionsIcon"
              className="fa fa-user-circle"
              aria-hidden="true"
              style={{
                fontSize: 32,
                color: "#607D8B",
              }}
            ></i>
          </span>
          <span
            style={{
              color: "#607D8B",
              marginRight: 10,
              fontWeight: 500,
              fontSize: "16px",
              textTransform: "capitalize",
            }}
          >
            {leadDetails &&
              (leadDetails.firstName
                ? `${leadDetails.firstName} ${
                    leadDetails.lastName ? leadDetails.lastName : ""
                  }`
                : leadDetails.email)}
          </span>

          <i
            className="fa fa-chevron-down"
            style={{
              fontWeight: 200,
              fontSize: "12px",
              color: "#607D8B",
              transform: "translateY(-1px)",
            }}
          ></i>
        </span>
      </OverlayTrigger>
    </div>
  );
};

export default UserProfile;
