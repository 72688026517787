import axios from "axios";
import $ from "jquery";

const API_FormLoader = (formUrl, successMethod, failureMethod) => {
  $.getScript(formUrl)
    .done((response) => {
      successMethod(response)
    })
    .fail((error) => {
      failureMethod(error);
    })
}

export { API_FormLoader };
