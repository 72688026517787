import React, { useEffect } from "react";
import { useState } from "react";
import LoginFormContainer from "../../containers/LoginFormContainer";
import RegisterContainer from "../../containers/RegisterContainer";
import ForgotPasswordForm from "../PreLoginForms/ForgotPasswordForm";

const SigninWidget = ({ state, logo }) => {
 const [widgetState, setWidgetState] = useState(state);
 const changeWidgetState = (widgetState) => {
  setWidgetState(widgetState);
 };
 const [showComponent, setShowComponent] = useState({
  registerForm: false,
  loginForm: true,
  forgotPasswordForm: false,
 })

 useEffect(() => {
  if (widgetState === "REGISTER") {
   setShowComponent({
    ...showComponent,
    registerForm: true,
    loginForm: false,
    forgotPasswordForm: false
   })
  }
  if (widgetState === "LOGIN") {
   setShowComponent({
    ...showComponent,
    registerForm: false,
    loginForm: true,
    forgotPasswordForm: false
   })
  }
  if (widgetState === "FORGOTPASSWORD") {
   setShowComponent({
    ...showComponent,
    registerForm: false,
    loginForm: false,
    forgotPasswordForm: true
   })
  }

 }, [widgetState])



 return (<>
  <div className="form-container">
   <div className={`d-none ${showComponent.registerForm && "d-block"}`}>
    <RegisterContainer changeWidgetState={changeWidgetState} />
   </div>
   <div className={`d-none ${showComponent.loginForm && "d-block"}`}>
    <LoginFormContainer changeWidgetState={changeWidgetState} />
   </div>
   <div className={`d-none ${showComponent.forgotPasswordForm && "d-block"}`}>
    <ForgotPasswordForm changeWidgetState={changeWidgetState} />
   </div>
  </div>
 </>)
};

export default SigninWidget;
