import React from "react";
import Skeleton from "react-loading-skeleton";

const CardLoader = (props) => {

  let { count } = props;
  return (
    <div className="style-card">
      <Skeleton count={count ? count : 5} />
    </div>
  );
};

export default CardLoader;
