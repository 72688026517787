import { Action } from "../actions/index";
import AuthHandler from "../utility/AuthHandler";

function authSessionInfo(state, action) {
  if (action.type == Action.Logout.Request) {
    AuthHandler.ExpireAuthSessionInfoCookie();
  }
  var authSessionState;
  if (AuthHandler.IsAuthSessionInfoCookiePresent()) {
    authSessionState = AuthHandler.GetAuthSessionInfoCookieValue(
      "authSessionState"
    );
  } else {
    authSessionState = {
      isAuthenticated: false,
      leadId: "",
      authKey: "",
    };
    AuthHandler.SetAuthSessionInfoCookie(authSessionState);
  }

  if (state) {
    switch (action.type) {
      case Action.AuthSessionInfo.IsAuthenticated:
        authSessionState = Object.assign({}, state, {
          isAuthenticated: action.isAuthenticated,
        });
        break;
      case Action.AuthSessionInfo.UpdateLoginInfo:
        authSessionState = Object.assign({}, state, {
          isAuthenticated: action.loginInfo.isAuthenticated,
          leadId: action.loginInfo.leadId,
          authKey: action.loginInfo.authKey,
        });
        break;
      default:
        authSessionState = state;
        break;
    }
  }

  return authSessionState;
}

export default authSessionInfo;
