import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import AuthHandler from "../../utility/AuthHandler";
import { API_Login } from "../../api/signing-request";
import { API_GetLeadsById,API_UpdateLead  } from "../../api/lead-activity-api";
import { API_FormLoader } from "../../api/form-loader"
import LoadingScreen from "../Loader/Loader";
import CardLoader from "../Skeleton/Card";
import config from "../../config";
import queryString from 'query-string';

import "./style.css";
/* global lsq_setupForm */

class Registration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formCardLoader: true,
      fullScreenLoader: false,
    };
  }

  loadOverrideCSS = () => {
    // Changing the button Text
    window.lsqFormContainer.querySelector(".lsq-form-action-btn").textContent =
      "Sign Up";

    /* Custom Error Message for Passwrod Field when
     Regex is Applied from platform */
    window.lsqFormContainer.querySelector(".lsq-form-action-btn").addEventListener("click", () => {

      // Accessing Password Error Message Element inner Text
      let passwordErrorMsg = window.lsqFormContainer.querySelector(".mx-custom-password-textfield-focused + span").innerHTML;

      if (passwordErrorMsg === "Invalid Password") {
        // Replacing current text with CUSTOM text
        window.lsqFormContainer.querySelector(".mx-custom-password-textfield-focused + span").innerHTML = "Should contain atleast 8 characters and 1 numeric character"
      }
    })

   try{
    window.lsqFormContainer.querySelector('.lsq-form-password-item [data-schemaname="mx_Form_Special_Password"]').setAttribute("placeholder", "Password *");

    window.lsqFormContainer.querySelector('.lsq-form-confirm-password-item [data-schemaname="mx_Form_Special_Password"]').setAttribute("placeholder", "Confirm Password *");
   }
   catch{
       console.log("error");
   }

    this.setState({ formCardLoader: false });
  };

  // this function will get executed after successful signup
  login = (payload) => {
    const that = this;
    API_Login(payload)
      .then(function (response) {
        const utmValues = queryString.parse(window.location.search);
        let utm_referralURL = window.location.href;
        let utm_medium = "";
        let utm_campaign = "";
        let utm_source = "";
        if (utmValues && utmValues.utm_medium) {
          utm_medium = utmValues.utm_medium;
        }

        if (utmValues && utmValues.utm_campaign) {
          utm_campaign = utmValues.utm_campaign;
        }

        if (utmValues && utmValues.utm_source) {
          utm_source = utmValues.utm_source;
        }
        const updateLeadData = {
          leadId: response.data.LeadId,
          data: [
            {
              "Attribute": "Source",
              "Value": utm_source
            },
            {
              "Attribute": "SourceCampaign",
              "Value": utm_campaign
            },
            {
              "Attribute": "SourceMedium",
              "Value": utm_medium
            },
          ]
        };

        const updateLeadDatatemUTMElse = {
          leadId: response.data.LeadId,
          data: [
            {
              "Attribute": "Source",
              "Value": "Direct Traffic-Apply now"
            },
          ]
        };
        API_GetLeadsById({
          leadId: response.data.LeadId,
        })
          .then(function (res) {
            const completeLeadDetails = res.data.message[0];
            const authSessionInfo = {
              isAuthenticated: true,
              leadId: response.data.LeadId,
              authKey: response.data.AuthKey,
            };
            if (completeLeadDetails) {
              if (completeLeadDetails.Source === null &&
                completeLeadDetails.SourceCampaign === null &&
                completeLeadDetails.SourceMedium === null) {
                try {
                  if (utm_source) {
                    API_UpdateLead(updateLeadData).then((res) => {
                      AuthHandler.SetAuthSessionInfoCookie(authSessionInfo);
                      that.props.updateLoginInfo(authSessionInfo);
                      that.props.updateLeadDetails(completeLeadDetails);
                      that.setState({ fullScreenLoader: false });
                    }).catch((error) => {
                      console.log(' Error', error);
                    })

                  } else {
                    API_UpdateLead(updateLeadDatatemUTMElse).then((res) => {
                      AuthHandler.SetAuthSessionInfoCookie(authSessionInfo);
                      that.props.updateLoginInfo(authSessionInfo);
                      that.props.updateLeadDetails(completeLeadDetails);
                      that.setState({ fullScreenLoader: false });
                    }).catch((error) => {
                      console.log(' Error', error);
                    })
                  }
                } catch (error) {
                  console.log(' Error', error);
                }
              } else {
                AuthHandler.SetAuthSessionInfoCookie(authSessionInfo);
                that.props.updateLoginInfo(authSessionInfo);
                that.props.updateLeadDetails(completeLeadDetails);
                that.setState({ fullScreenLoader: false });
              }
            } else {
              AuthHandler.SetAuthSessionInfoCookie(authSessionInfo);
              that.props.updateLoginInfo(authSessionInfo);
              that.props.updateLeadDetails(completeLeadDetails);
              that.setState({ fullScreenLoader: false });
            }
          })
          .catch(function (error) {
            console.log(error, "error");
            window.location.href = "/";
            that.setState({ fullScreenLoader: false });
          });
        // for after signup success message
        localStorage.setItem("isSuccess", true);
      })
      .catch(function (error) {
        that.setState({ fullScreenLoader: false });
      });
  };

  onLSQFormSubmissionSuccess = () => {
    window.lsqFormContainer.querySelector(".ms-message-txt").textContent =
      "Account created successfully. You are now being logged in";

    console.log(">>>> LSQ Form Submit <<<<");
    this.setState({ fullScreenLoader: true });
    const email = document.querySelector(
      'input[data-schemaname="EmailAddress"]'
    ).value;
    const password = document.querySelector(
      'input[data-schemaname="mx_Form_Special_Password"]'
    ).value;

    this.login({ EmailAddress: email, Password: password });
  };

  onLSQFormSaveFailure = (e) => {
    window.lsqFormContainer.querySelector(".ms-message-txt").textContent =
      "User already exists";
  };

  formSuccessMethod = (response) => {
    lsq_setupForm({
      id: config.lsqRegistrationFormId,
      authKeyProvider: AuthHandler.GetAuthKey,
    });
  }

  formFailureMethod = (error) => {
    console.log(error);
    window.location.href = "/";
  }

  componentDidMount() {

    API_FormLoader(config.lsqRegistrationFormBaseURL, this.formSuccessMethod, this.formFailureMethod);

    // $.getScript(REGISTRATION_FORM.FORM_URL)
    //   .done(function (data) {
    //     lsq_setupForm({
    //       id: REGISTRATION_FORM.FORM_ID,
    //       authKeyProvider: AuthHandler.GetAuthKey,
    //     });
    //   })
    //   .fail(function (error) {
    //     window.location.href = "/";
    //   });

    if (window.lsqFormContainer) {
      window.lsqFormContainer.addEventListener(
        "lsqformloadcomplete",
        this.loadOverrideCSS
      );

      window.lsqFormContainer.addEventListener(
        "lsqformsubmissionsuccess",
        this.onLSQFormSubmissionSuccess
      );
      window.lsqFormContainer.addEventListener(
        "LsqFormSaveFailure",
        this.onLSQFormSaveFailure
      );
    }
  }

  render() {
    return (
      <>
        {this.state.fullScreenLoader && <LoadingScreen />}
        <div className="registration-form-container registration">
          <div
            id="lsq-form-modal"
            ref={(elem) => (window.lsqFormContainer = elem)}
            data-form-id={config.lsqRegistrationFormId}
            className="modal-v4 fullscreen external lsq-external-form-container"
          >
            <div className="lsq-form-container-wrapper"></div>
            <div className="lsq-form-hidden-fields">

              <input
                id="lsq-api-service-url"
                name="lsq-api-service-url"
                type="hidden"
                value={config.portalBaseURL}
              />
              <input
                id="lsq-app-url"
                name="lsq-app-url"
                type="hidden"
                value={config.lsqFormBaseURL}
              />
              <input
                id="lsq-form-id-hidden"
                name="lsq-form-id-hidden"
                type="hidden"
                value={config.lsqRegistrationFormId}
              />
            </div>
          </div>
        </div>

        {this.state.formCardLoader ? <CardLoader count="23" /> :
          <div className="form-toggle">
            <div className="login-toggle">
              <span>
                Already have an Account?
              </span>
              <a
                href="javascript:void(0)"
                onClick={() => this.props.changeWidgetState("LOGIN")}
              >
                Login
              </a>
            </div>
          </div>}


      </>
    );
  }
}

export default withRouter(Registration);
