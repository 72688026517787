/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  API_GetLeadActivity,
  API_GetLeadsById,
} from "../../api/lead-activity-api";
import LoadingScreen from "../Loader/Loader";
import { getSessionData } from "../../utility/common";
import ApplicationCard from "./ApplicationCard";
import applyNowImage from "../../images/apply-now-image.png";

const ApplicationHistory = (props) => {
  const [applicationData, setApplicationData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [count, setCount] = useState(null);
  const [course, setCourse] = useState("");
  // const [applicationId, setApplicationId] = useState("");
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const userInfo = getSessionData("user-info");
    setUserDetails(userInfo);
    API_GetLeadActivity({
      leadId: props.authSession.leadId,
      activityEvent: "212",
    })
      .then(function (response) {
        console.log(response.data.message, "Dashboard data");

        setApplicationData(
          response.data.message && response.data.message.ProspectActivities
        );
        setCount(response.data.message && response.data.message.RecordCount);
        setIsLoading(false);
      })
      .catch(function (error) {
        alert("Something Went Wrong!!!");
      });

    // lead
    API_GetLeadsById({
      leadId: props.authSession.leadId,
      // activityEvent: "212",
    })
      .then(function (response) {
        const leadsInfo = response.data.message[0];
        console.log(leadsInfo, "leadinfo");

        const course = leadsInfo.mx_Course_Applied;
        const name = leadsInfo.FirstName + " " + leadsInfo.LastName;
        setUsername(name);

        setCourse(course);
        console.log("username", username);
      })
      .catch(function (error) {
        alert("Something Went Wrong!!!");
      });
  }, []);

  const renderApplication = (application, index) => {
    console.log("c=", application, index);

    const activityId = application.Id ? application.Id : "";
    const lastUpdate = application.ModifiedOn ? application.ModifiedOn : "";
    const applicationId =
      application.ActivityFields && application.ActivityFields.mx_Custom_1
        ? application.ActivityFields.mx_Custom_1
        : "";

    const applicationStatus =
      application.ActivityFields && application.ActivityFields.mx_Custom_2
        ? application.ActivityFields.mx_Custom_2
        : "";

    console.log("activityId=", activityId);
    console.log("last update=", lastUpdate);
    console.log("application ID=", applicationId);
    console.log("applicationStatus=", applicationStatus);
    console.log("username", username);

    //  const applicationStatus="pending";
    if (applicationStatus.toLowerCase() === "application pending") {
      return (
        <ApplicationCard
          applicationId={applicationId}
          username={username}
          course={course}
          status={applicationStatus}
          lastUpdate={lastUpdate}
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/Form/${activityId}`,
            text: "Continue Application",
          }}
        />
      );
    } else if (applicationStatus.toLowerCase() === "application submitted") {
      return (
        <ApplicationCard
          applicationId={applicationId}
          username={username}
          course={course}
          status={applicationStatus}
          lastUpdate={lastUpdate}
          modifiedOn={lastUpdate}
          applicationCTA={{
            link: `/Home/ApplicationForm/Preview/${activityId}?`,
            text: "Application Preview",
          }}
        />
      );
    }
  };

  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <div className="application-container">
          <div
            className="container apply-now-container"
            style={count === 0 ? {} : { display: "none" }}
          >
            <img src={applyNowImage} alt="apply-now" />
            <br />
            <div className="mt-5">You have not applied to any program.</div>
            <br />
            <a href="/Home/Form" className="btn btn-primary mt-1">
              Apply Now
            </a>
          </div>

          {console.log("co==", applicationData)}

          <div className="application-card-list">
            <div className="container">
              {
                applicationData.map((application, index) =>
                  renderApplication(application, index)
                )[0]
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicationHistory;
