import axios from "axios";
import AuthHandler from "../utility/AuthHandler";
import { LSQ_API, LAPP_API } from "../constants/endpoint";

var lsqApiInstance = axios.create({
  baseURL: LSQ_API.BASE,
  timeout: 10000,
  headers: { Authorization: AuthHandler.GetAuthKey() },
});

let lappApiInstance = axios.create({
  baseURL: LAPP_API.BASE,
  timeout: 10000,
  headers: { Authorization: AuthHandler.GetAuthKey() },
});

const API_GetLeadsById = (payload) => {
  return API_interceptor_Lapp({
    method: "get",
    url: LSQ_API.ENDPOINTS.GET_LEAD_BY_ID,
    params: {
      id: payload.leadId,
    },
  });
};

const API_GetLeadActivity = (payload) => {
  return API_interceptor_Lapp({
    method: "post",
    url: LSQ_API.ENDPOINTS.GET_LEAD_ACTIVITY,
    params: {
      leadId: payload.leadId,
    },
    data: {
      Parameter: {
        leadId: payload.leadId,
        ActivityEvent: payload.activityEvent,
      },
    },
  });
};

const API_CreateActivity = (payload) => {
  return API_interceptor_Lapp({
    method: "post",
    url: LSQ_API.ENDPOINTS.CREATE_ACTIVITY,
    params: {},
    data: payload,
  });
};

const API_UpdateActivity = (payload) => {
  return API_interceptor_Lapp({
    method: "post",
    url: LSQ_API.ENDPOINTS.UPDATE_ACTIVITY,
    params: {},
    data: payload,
  });
};

const API_ActivityDetailsById = (payload) => {
  return API_interceptor_Lapp({
    method: "get",
    url: LSQ_API.ENDPOINTS.GET_ACTIVITY_DETAILS_BY_ID,
    params: {
      activityId: payload.activityId,
      getFileURL: true,
    },
  });
};

const API_UpdateLead = (payload) => {
  return API_interceptor_Lapp({
    method: "post",
    url: LSQ_API.ENDPOINTS.UPDATE_LEAD,
    params: {
      leadId: payload.leadId,
    },
    data:payload.data,
  });
};

const API_interceptor_Lapp = (body) => {
  return lsqApiInstance({
    method: "post",
    url:
      "https://lapps-in21.leadsquared.com/execute?name=da_24822_e8143b11&stage=Test",
    data: body,
    json: true,
    headers: {
      "x-api-key": "gf2uXRZTG56vf2Knr2ZZc7GFfjbwlMi827pBln5n",
      "Access-Control-Allow-Origin":
        "https://imsbbabca-xyamadh1.lsqportal-test.com/",
    },
  });
};

export {
  API_GetLeadsById,
  API_GetLeadActivity,
  API_UpdateActivity,
  API_ActivityDetailsById,
  API_CreateActivity,
  API_UpdateLead
};
