import React from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./style.css";

const LoadingScreen = () => {
  return (
    <div className="loader-screen">
      <Loader type="BallTriangle" color="#ffffff" height={100} width={100} />
    </div>
  );
};

export default LoadingScreen;
