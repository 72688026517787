import React, { useEffect, useState } from "react";
import {
  API_GetLeadsById,
  API_GetLeadActivity,
  API_ActivityDetailsById,
} from "../../api/lead-activity-api";
import LoadingScreen from "../Loader/Loader";
import "./style.css";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { isMobile, isTablet } from "react-device-detect";
import Button from "../Button";

const ApplicationPreview = (props) => {
  const [applicationNumber, setApplicationNumber] = useState({});
  const [activityData, setActivityData] = useState({});
  const [isLoader, setIsLoader] = useState(true);
  const [url, seturl] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [deviceWidth, setWidth] = useState(0);

  // First Mount Call
  useEffect(() => {
    const activityIdFromParam = props.location.pathname.split("/").reverse()[0];
    API_ActivityDetailsById({ activityId: activityIdFromParam })
      .then(function (res) {
        const activityDetails = res.data.message;
        console.log(activityDetails, "activityDetails");
        const url =
          activityDetails.Fields[5].CustomObjectFormProperties.FieldProperties
            .FormMetaData[1].FileURL;
        seturl(url);
        const applicationNumber = activityDetails.Fields[3].Value;
        setApplicationNumber(applicationNumber);
        if (activityDetails) {
          setActivityData(activityDetails);
          setIsLoader(false);
        } else {
          window.location.href = "/";
        }
      })
      .catch((error) => {
        window.location.href = "/";
        console.log(error, "activity API");
      });
    const deviceWidth = setdeviceWidth();
    setWidth(deviceWidth);
  }, []);

  const downloadPDF = () => {
    window.open(url);
  };

  //Setting Document PDF Page Number
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  //Function for setting width of document pdf with respect to display
  const setdeviceWidth = () => {
    let pdfwidth = 0;
    if (isMobile) {
      pdfwidth = 320;
    } else if (isTablet) {
      pdfwidth = 600;
    } else {
      pdfwidth = 1100;
    }
    return pdfwidth;
  };

  return (
    <>
      {isLoader ? (
        <LoadingScreen />
      ) : (
        <div className="application-preview-wrapper">
          <div className="container">
            <div className="button-container">
              <h4>{applicationNumber}</h4>
              <Button
                type="button"
                className="download-button"
                text="Download PDF"
                status={false}
                handleClick={() => {
                  downloadPDF();
                }}
              />
            </div>
            <section className="application-template-wrapper">
              <div>
                <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                  {Array.apply(null, Array(numPages))
                    .map((x, i) => i + 1)
                    .map((page) => (
                      <Page pageNumber={page} width={deviceWidth} />
                    ))}
                </Document>
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplicationPreview;
