import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import config from "../../config";
import {
  API_UpdateActivity,
  API_GetLeadActivity,
  API_CreateActivity,
} from "../../api/lead-activity-api";
import AuthHandler from "../../utility/AuthHandler";
import LoadingScreen from "../Loader/Loader";
import "./style.css";
// import "./progressBar.css";
import "./schemaModified.css";
/* global lsq_setupForm */
/* global lsq_setupPortalProcess */

class ApplicationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      progressBarList: [],
      activityId: null,
      count: null,
      activeApplication: [],
    };
  }

  loadOverrideCSS = () => {
   try{

        // Creating the Progress bar list
        const progressList = Array.from(
            window.lsqFormContainer.querySelectorAll(".mx-form-step-indicator")
          ).map((item) => item.dataset.content);
              // Reloading the Preview Form
    this.reloadApplicationPreview();

    //Setting Loader to false
    this.setState({ progressBarList: progressList, loader: false });
  
      window.lsqFormContainer.querySelector(".lsq-form-action-btn").textContent =
        "Submit";
  
      // Changing the button Text
      window.lsqFormContainer.querySelector(
        ".lsq-form-action-next-tab"
      ).textContent = "Save & Next";
  
   }
   catch {
    console.log("error");
  };
      
  };

  onLSQFormSubmissionSuccessAtEachStep = (e) => {
    console.log(">>>> Save At Each Step <<<<");

    // Activity Id Generated on click of Application Form Next button
    const activityId =
      e.detail.data.ActivityDetails.length !== 0
        ? e.detail.data.ActivityDetails[0].ActivityId
        : null;

    // If Activity Id is there and in cookie activity id is not present
    // then set the cookie and reload the Application Preview
    if (activityId) {
      if (document.cookie.indexOf("activityId") === -1) {
        AuthHandler.SetCookie("activityId", activityId);
        this.reloadApplicationPreview();
      } else {
        AuthHandler.RemoveCookie("activityId");
        AuthHandler.SetCookie("activityId", activityId);
        this.reloadApplicationPreview();
      }
      this.setState({ activityId });
    }
  };

  onLSQFormSubmissionSuccess = () => {
    console.log(">>>> LSQ Form Submit <<<<");
    this.setState({ loader: true });
    // Updating the Application Status
    this.updateActivity(
      "mx_Custom_2",
      "Application Submitted",
      "Application Status Change"
    );
  };

  // Get Activity Id From the Query String
  getActivityIdFromQueryParams = () => {
    const activityId =
      this.props.location.pathname.split("/").length === 4
        ? this.props.location.pathname.split("/").reverse()[0]
        : null;

    return activityId;
  };

  reloadApplicationPreview() {
    console.log("Review");
    if (
      $(
        'div[data-special-field-id="0b413c64-cbaf-44c3-b98d-da1c82044bfd"] iframe'
      )
    ) {
      let iframe = $(
        '[data-special-field-id="0b413c64-cbaf-44c3-b98d-da1c82044bfd"] iframe'
      ).attr("src");
      if (iframe) {
        $(
          '[data-special-field-id="0b413c64-cbaf-44c3-b98d-da1c82044bfd"] iframe'
        ).attr("src", iframe);
      } else {
        $(
          '[data-special-field-id="0b413c64-cbaf-44c3-b98d-da1c82044bfd"] iframe'
        ).attr("src", config.iframeURL);
      }
    }
  }

  // Updates the activity based on the custom status
  updateActivity = (schemaName, status, note) => {
    const { activityId } = this.state;
    const payload = {
      ProspectActivityId: activityId,
      ActivityEvent: 212,
      ActivityNote: note,
      Fields: [
        {
          SchemaName: schemaName,
          Value: status,
        },
      ],
    };

    API_UpdateActivity(payload)
      .then((res) => {
        this.setState({ loader: false });
        this.props.history.push(`/Home/ApplicationFormSuccess`);
      })
      .catch((error) => console.log(""));
  };

  onLSQFormLoadError = (e) => {
    console.log("ERROR ==>", e);
    // Expire cookie
    AuthHandler.ExpireAuthSessionInfoCookie();
    //log out
    window.location.href = "/";
  };

  componentDidMount() {
    const activityId = this.getActivityIdFromQueryParams();
    const LeadId = AuthHandler.GetLeadId();
    let createActivityId = "";

    const getApplicationCount = API_GetLeadActivity({
      leadId: this.props.authSession.leadId,
      activityEvent: "212",
    });

    getApplicationCount
      .then(({ data }) => {
        console.log("1");
        const { RecordCount, ProspectActivities } = data;
        const recordedActivityId =
          ProspectActivities &&
          ProspectActivities.length > 0 &&
          ProspectActivities[0].Id;

        if (RecordCount > 0 && recordedActivityId !== activityId) {
          console.log("2");
          window.location.href = "/";
        } else {
          if (!activityId) {
            console.log("3");
            console.log("lead id", LeadId);
            const createActivityData = {
              RelatedProspectId: LeadId,
              ActivityEvent: 212,
              ActivityNote: "Activity Initiated",
              ActivityDateTime: moment().format("YYYY-MM-DD hh:mm:ss"),
              Fields: [
                {
                  SchemaName: "mx_Custom_2",
                  Value: "Application Pending",
                },
              ],
            };

            // Create a Activity against a lead.
            API_CreateActivity(createActivityData)
              .then(({ data }) => {
                // console.log(data.Message.Id, "Created Activity ID");

                createActivityId = data.message.Message.Id;
                const actualPathName = this.props.location.pathname.split("/");
                const pathname = this.props.location.pathname;
                if (actualPathName.length === 3) {
                  this.props.history.push(`${pathname}/${createActivityId}`);
                }
                AuthHandler.SetCookie("activityId", createActivityId);

                $.getScript(config.lsqFormURL)
                  .done(function (data) {
                    lsq_setupPortalProcess({
                      id: config.lsqFormProcessId,
                      authKeyProvider: AuthHandler.GetAuthKey(),
                      leadId: LeadId,
                      templateName: "Number",
                      themeOptions: {
                        primaryButtonColor: "#3657A4",
                      },
                      // utmParamsProvider: AuthHandler.GetUTMObject,
                      activityId: createActivityId,
                    });
                  })
                  .fail(function (error) {
                    window.location.href = "/";
                  });

                this.setState({ activityId: createActivityId });
              })
              .catch((e) => (window.location.href = "/"));
          } else {
            console.log("4", activityId);
            console.log("id", config.lsqFormProcessId, config.lsqFormURL);
            AuthHandler.SetCookie("activityId", activityId);
            $.getScript(config.lsqFormURL)
              .done(function (data) {
                lsq_setupPortalProcess({
                  id: config.lsqFormProcessId,
                  authKeyProvider: AuthHandler.GetAuthKey(),
                  leadId: LeadId,
                  templateName: "Number",
                  themeOptions: {
                    primaryButtonColor: "#3657A4",
                  },
                  // utmParamsProvider: AuthHandler.GetUTMObject,
                  activityId: activityId,
                });
              })
              .fail(function (error) {
                window.location.href = "/";
              });
            this.setState({ activityId: activityId });
          }
        }
      })
      .catch(function (error) {
        console.log("Error : ", error);
        alert("Something Went Wrong. Please Try Again");
        window.location.href = "/";
      });

    if (window.lsqFormContainer) {
      window.lsqFormContainer.addEventListener(
        "lsqformloadcomplete",
        this.loadOverrideCSS
      );
      window.lsqFormContainer.addEventListener(
        "lsqformsubmissionsuccessateachstep",
        this.onLSQFormSubmissionSuccessAtEachStep
      );
      window.lsqFormContainer.addEventListener(
        "lsqformsubmissionsuccess",
        this.onLSQFormSubmissionSuccess
      );
      window.lsqFormContainer.addEventListener(
        "LSQFormLoadError",
        this.onLSQFormLoadError
      );
      window.lsqFormContainer.addEventListener(
        "lsqformpaymentsuccess",
        this.updatePaymentStatus
      );
    }
  }

  render() {
    return (
      <>
        {this.state.loader && <LoadingScreen />}
        <div className="application-form-container">
          <div
            id="lsq-form-modal"
            data-process-id={config.lsqFormProcessId}
            class="modal-v4 fullscreen external lsq-external-form-container"
            ref={(elem) => (window.lsqFormContainer = elem)}
          >
            <div class="lsq-form-container-wrapper"></div>
            <div class="lsq-form-hidden-fields">
              <input
                id="lsq-authKey"
                name="lsq-authKey"
                type="hidden"
                value="b3ZZMnB5aXFVa2U2L2VLWlpsQUszT2JYYTlRaTcvcnpHSmRFajlYOXlabUtvTFZiWitJb1NnOTMwNy90a2QvUFdwcS9aMzlwYlVoYW5KQXQ2KzBsYloyeENLKzNyeCtjVVpoaFBDUjBaTDVRdFFIQnVZYXIzYzAzdTRBamFoVFF3cE01a2JwamVwUlRsYmdVdk5uVU5jWWc4S1NqcEFXc1VFNnRVa1c0d1BkN2Y0VjFod1JxZ1R1YmdPcFkvczJuN1N6MW9LOXRrdEVmWEgxV2NHTHd6QT09"
              />
              <input
                id="lsq-api-service-url"
                name="lsq-api-service-url"
                type="hidden"
                value="https://portalapi-in21.leadsquared.com/api/Form"
              />
              <input
                id="lsq-app-url"
                name="lsq-app-url"
                type="hidden"
                value="https://in21.leadsquared.com"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ApplicationForm);
