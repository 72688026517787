import React from "react";
import { Link, withRouter } from "react-router-dom";
import moment from "moment";
import "./style.css";

const ApplicationCard = ({
  course,
  applicationId,
  courseStatus,
  paymentStatus,
  modifiedOn,
  applicationCTA,
  username,
  status,
  buttonText,
  buttonText1,
  link,
  lastUpdate


}) => {
  const renderStatus = (status) => {
    switch (status) {


      case "Application Pending": {
        return <div className="status-pill status-pending">Pending</div>;
      }

      case "Application Submitted": {
        return (
          <div className="status-pill status-completed">
            Application Submitted
          </div>
        );
      }

      case "in review": {
        return <div className="status-pill status-completed">In Review</div>;
      }

      case "payment in verification": {
        return (
          <div className="status-pill status-completed">Payment in Review</div>
        );
      }

      case "course offered": {
        return <div className="status-pill status-pending">Offered</div>;
      }


      case "form filled and payment verified": {
        return (
          <div className="status-pill status-completed">
            Form filled and Payment verified
          </div>
        );
      }

      default:
    }
  };
  const applicationModifiedFormat = (str) => {
    return moment(str).add(5.5, "h").format("DD MMMM YYYY, h:mma");
  };

  return (
    <div className="dashboardcard-container">
      <div className="container mx-auto">
        <div className="row padding">
          <div className="col-sm-6 col-12 pt-3" style={{ fontSize: '24px' }}>
            <b>
              Your Application
                        </b>

          </div>
          <div className="col-sm-6 col-12 text-align py-3">

            {applicationCTA.link && <Link to={applicationCTA.link}><button className="btn btn-primary ">{applicationCTA.text}</button></Link>}
            {buttonText1 ? <>
              <Link to={link}><button className="btn btn-primary ml-1">{buttonText1}</button></Link>

            </> : <></>}
          </div>
        </div>
        <div className="card border-0 card-container">
          <div className="card-body ml-3 m-2">

            <div className="row">
              <div className="col-lg-3 col-sm-5 col-6 p-1 textGrey">
                Student Name :
                            </div>
              <div className="col-lg-8 col-sm-5 col-6 p-1 textBlack">
                {username}
              </div>
              <div className="col-lg-3 col-sm-5 col-6 p-1 textGrey">
                Application Id  :
                            </div>
              <div className="col-lg-8 col-sm-5 col-6 p-1 textBlack">
                {applicationId}
              </div>
              <div className="col-lg-3 col-sm-5 col-6 p-1 textGrey">
                Course Name :
                            </div>
              <div className="col-lg-8 col-sm-5 col-6 p-1 textBlack">
                {course}
              </div>
              <div className="col-lg-3 col-sm-5 col-6 p-1 textGrey">
                Application Status  :
                            </div>
              <div className="col-lg-8 col-sm-5 col-6 p-1 textBlack">
                {renderStatus(status)}
                {/* {status} */}
              </div>
              <div className="col-lg-3 col-sm-5 col-6 p-1 textGrey" >
                Last Update :
                            </div>
              {status === "Admission Confirmed" ? <>
                <div className="col-lg-8 col-sm-5 col-6 p-0 textBlack" >
                  {moment(Date()).format('DD MMMM YYYY, h:mm:ss a')} (IST)
                            </div></> : <><div className="col-lg-8 col-sm-5 col-6 p-1 textBlack">
                  {applicationModifiedFormat(lastUpdate)}

                </div></>}
            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default withRouter(ApplicationCard);
