import { connect } from "react-redux";
import ApplicationPreview from "../components/ApplicationPreview";
import { withRouter } from "react-router-dom";

function mapStateToProps(state) {
  return {
    authSession: state.authSessionInfo,
  };
}

export default withRouter(connect(mapStateToProps)(ApplicationPreview));
