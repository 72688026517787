import { connect } from "react-redux";
import ApplicationForm from "../components/Forms/ApplicationForm";
import { withRouter } from "react-router-dom";

const mapStateToProps = state => {
  return {
    authSession: state.authSessionInfo
  };
};

export default withRouter(connect(mapStateToProps)(ApplicationForm));
